import { MutationTree } from "vuex";
import {
  PatientState,
  SET_PATIENT,
  SET_PATIENT_MASTER,
  SetPatient,
  SetPatientMaster,
  SET_PATIENT_BASE,
  SET_BREAD_ITEMS,
  BreadItem
} from "./types";

export const mutations: MutationTree<PatientState> = {
  [SET_PATIENT](state, input: SetPatient) {
    state.info = input.info;
    state.detail = input.detail;
    state.report_count = input.report_count;
  },
  [SET_PATIENT_MASTER](state, input: SetPatientMaster) {
    state.master.relation_institutions = input.master.relation_institutions;
    state.master.medical_institutions = input.master.medical_institutions;
    state.master.ibow_ai_generate_agreement_status =
      input.master.ibow_ai_generate_agreement_status;
  },
  [SET_PATIENT_BASE](state, input: SetPatientMaster) {
    state.master.patient_menus = input.master.patient_menus;
    state.office_id = input.master.office_id ?? 0;
  },
  [SET_BREAD_ITEMS](state, input: BreadItem[]) {
    state.bread_items = input;
  }
};
