








































































































































































import { Mixins, Component, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import PatientMixin from "@/mixins/patientMixin";
import PdfLinkMixin from "@/mixins/pdfLinkMixin";
import { COMMON } from "#/const";
import { PdfLink, PdfLinkMail, PdfLinkReport } from "#/model/patient/pdfLink";
import {
  HeadersSend as HeadersPdf,
  Item as ItemPdf,
  DefaultItem as DefaultItemPdf,
  SearchCond as SearchCondPdf
} from "@/components/patient/pdf/list";
import {
  HeadersSend as HeadersPersonnel,
  Item as ItemPersonnel,
  DefaultItem as DefaultItemPersonnel,
  INSTITUTION_NUMBER,
  INSTITUTION_STRING
} from "@/components/master/doctorpersonnel/list";
import PatientHeader from "@/components/patient/PatientHeader.vue";
import PatientExpantionAreas from "@/components/patient/PatientExpantionAreas.vue";
import PatientSaveArea from "@/components/patient/PatientSaveArea.vue";
import MailPreviewDialog from "@/components/patient/pdflink/MailPreviewDialog.vue";

@Component({
  components: {
    PatientExpantionAreas,
    PatientSaveArea,
    MailPreviewDialog
  }
})
export default class PatientPdfLinkMailDetail extends Mixins(
  UtilMixin,
  PatientMixin,
  PdfLinkMixin
) {
  /** 利用者ヘッダー */
  @Prop() readonly pheader!: PatientHeader;

  /** 帳票の提出状況 */
  private REPORT_STATUS = COMMON.REPORT_STATUS;

  private HeadersPersonnel = HeadersPersonnel;
  private HeadersPdf = HeadersPdf;

  private patientId = 0;
  private pdfLinkId = 0;

  created() {
    this.patientId = Number(this.$route.params.id);
    this.pdfLinkId = Number(this.$route.params.pdf_link_id);
    const searchCond = {
      statuses: [this.REPORT_STATUS.SUBMIT, this.REPORT_STATUS.REMAND]
    } as SearchCondPdf;

    Promise.allSettled([
      this.fetchPdfs(this.patientId, searchCond),
      this.fetchPdfLink(this.pdfLinkId)
    ]).then(() => {
      this.fromSelectedPersonnels(this.pdfLink.mails);
      this.fromSelectedPdfs(this.pdfLink.reports);
    });
  }

  mounted() {
    this.setDefaultHeader();
  }

  /** 送信時に選択していた担当者を、表示する */
  private fromSelectedPersonnels(mails: PdfLinkMail[]) {
    if (mails == null) {
      return; // 取得件数が0件の時nullになる
    }
    this.selectedPersonnels = mails.map(mail => {
      if (mail.institution_div === INSTITUTION_NUMBER.RELATION) {
        return this.convertApplyPersonnelToItem(mail);
      } else {
        return this.convertApplyDoctorToItem(mail);
      }
    });
  }

  /** 送信時に選択していた帳票を、選択状態にする */
  private fromSelectedPdfs(reports: PdfLinkReport[]) {
    if (reports == null) {
      return; // 取得件数が0件の時nullになる
    }
    this.selectedPdfs = reports.map(report => {
      return this.converLinkReportToItem(report);
    });
  }

  /** 変換：引き当て担当者→表項目 */
  private convertApplyPersonnelToItem(mail: PdfLinkMail): ItemPersonnel {
    return {
      ...DefaultItemPersonnel(),
      ...mail,
      list_id: `${INSTITUTION_NUMBER.RELATION}_${mail.personnel.personnel_id}`,
      personnel_name: mail.personnel.name,
      section_name: mail.personnel.section_name,
      institution_div_name: INSTITUTION_STRING.RELATION,
      institution_id: mail.personnel.id,
      institution_name: mail.personnel.relation_institution_name,
      is_deleted: mail.personnel.is_deleted === 1
    };
  }

  /** 変換：引き当て医師→表項目 */
  private convertApplyDoctorToItem(mail: PdfLinkMail): ItemPersonnel {
    return {
      ...DefaultItemPersonnel(),
      ...mail,
      list_id: `${INSTITUTION_NUMBER.MEDICAL}_${mail.doctor.doctor_id}`,
      personnel_name: mail.doctor.name,
      section_name: mail.doctor.section_name,
      institution_div_name: INSTITUTION_STRING.MEDICAL,
      institution_id: mail.doctor.id,
      institution_name: mail.doctor.medical_institution_name,
      is_deleted: mail.doctor.is_deleted === 1
    };
  }

  /** 変換：保存済みPDFリンク帳票→表項目 */
  private converLinkReportToItem(report: PdfLinkReport): ItemPdf {
    const matchPdf = this.pdfs.find(
      pdf =>
        pdf.report_type === report.report_type &&
        pdf.report_id === report.report_id
    );
    return {
      ...DefaultItemPdf(),
      ...report,
      list_id: `t${report.report_type}id${report.report_id}`,
      report_status: matchPdf?.report_status ?? 0,
      is_deleted: !matchPdf
    };
  }

  /** 送信先担当者や帳票を絞った、送信用PDFリンクメール */
  private toSendablePdfLink(pdfLink: PdfLink, isCopy: boolean) {
    this.pdfLink.mails = this.SendablePersonnels;
    this.pdfLink.reports = this.SendableReports;
    const reqPdfLink = this.deepCopy(pdfLink);

    if (isCopy) {
      // IDを初期化することで、コピー情報として保存できるようにする
      reqPdfLink.mails.forEach(mail => (mail.id = 0));
      reqPdfLink.reports.forEach(report => (report.id = 0));
    }

    return reqPdfLink;
  }

  private async clickExpire() {
    const msg =
      "このPDFリンクを無効にします。メールの受信者がPDFを閲覧できなくなりますが、よろしいでしょうか？";
    if (!(await this.$openConfirm(msg))) {
      return;
    }
    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, false);
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/expire",
      { pdf_link: reqPdfLink },
      () => this.back()
    );
  }

  /** ページを離れる */
  private back() {
    this.$router.back();
  }

  /** PDFリンクのコピー作成画面へ遷移する */
  private routePdfLinkCopy() {
    this.$router.push({
      name: "PatientPdfLinkEdit",
      params: {
        id: String(this.pdfLink.patient_id),
        pdf_link_id: String(this.pdfLink.id)
      },
      query: { mode: "copy" }
    });
  }

  /** 削除ボタンをクリック */
  private async clickDelete() {
    const confirmMsg = this.pdfLink.is_expired
      ? "削除します。よろしいですか？"
      : "削除します。送信したPDFリンクは閲覧できなくなりますが、よろしいですか？";
    if (!(await this.$openConfirm(confirmMsg))) {
      return;
    }
    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, false);
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/delete",
      { pdf_link: reqPdfLink },
      () => this.back()
    );
  }

  /** メール内容をプレビュー */
  private previewMailContent() {
    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, false);
    reqPdfLink.resend = 0;
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/preview",
      { pdf_link: reqPdfLink },
      res => {
        this.previewMailSubject = res.data.subject;
        this.previewMailBody = res.data.body;
        this.isOpenPreviewDialog = true;
      }
    );
  }

  /** 再送信ボタンをクリック */
  private async clickResend() {
    if (this.NoticeLinkMailEdit !== "") {
      await this.$openAlert(this.NoticeLinkMailEdit);
      return;
    }
    let msg = "メールを再送信します。";
    if (this.SendablePersonnels.length !== this.selectedPersonnels.length) {
      msg += "削除済み担当者にはメールをは再送信しません。";
    }
    if (this.SendableReports.length !== this.selectedPdfs.length) {
      msg += "差し戻された帳票や削除済み帳票は再送信しません。";
    }
    msg += "よろしいですか？";
    if (!(await this.$openConfirm(msg))) {
      return;
    }

    const reqPdfLink = this.toSendablePdfLink(this.pdfLink, true);
    this.postJsonCheck(
      window.base_url + "/api/patient/pdflink/resend",
      { pdf_link: reqPdfLink },
      () => this.back()
    );
  }
}
