import { PatientDispInfo, Detail } from "@/components/patient/types";
import { ReportCount } from "@/types";

export const name = "patient";

export const SET_PATIENT = "SET_PATIENT";

export const SET_PATIENT_MASTER = "SET_PATIENT_MASTER";

export const SET_PATIENT_BASE = "SET_PATIENT_BASE";

export const SET_BREAD_ITEMS = "SET_BREAD_ITEMS";

export interface PatientState {
  info: PatientDispInfo; //職員基本情報
  detail: Detail; //職員詳細
  report_count: ReportCount; //未提出書類
  master: PatientMaster; //マスター
  bread_items: BreadItem[]; //パンくずリスト
  office_id: number; //事業所ID
  error: boolean;
}

export interface SetPatient {
  info: PatientDispInfo;
  detail: Detail;
  report_count: ReportCount;
}

export interface SetPatientMaster {
  master: PatientMaster;
}

export interface PatientList {
  id: number;
  title: string;
  link: string;
  count: number;
  report_group: number;
}

export interface BreadItem {
  text: string;
  disabled: boolean;
  to: string;
  exact?: boolean;
}

export interface PatientMaster {
  /** 関連機関リスト */
  relation_institutions: RelationInstitutionPatient[];
  /** 医療機関リスト */
  medical_institutions: MedicalInstitutionPatient[];
  /** 利用者メニュー */
  patient_menus: PatientList[];
  /** 所属事業所ID */
  office_id?: number;
  /** iBowAI自動作成 契約状況 */
  ibow_ai_generate_agreement_status: IbowAiGenerateAgreementStatus;
}

export interface RelationInstitutionPatient {
  id: number;
  name: string;
  furigana: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  support_office_div: number;
  document_form: number;
  personnels: PersonnelPatient[];
}

export interface PersonnelPatient {
  id: number;
  full_name: string;
  furigana: string;
  is_empty: number;
  status: number;
  section_name: string;
  relation_institution_id: number;
  relation_institution_name: string;
  personnel_contacts: Contact[];
}

export interface MedicalInstitutionPatient {
  id: number;
  name: string;
  furigana: string;
  tel_area: string;
  tel_city: string;
  tel_num: string;
  doctors: DoctorPatient[];
}

export interface DoctorPatient {
  id: number;
  full_name: string;
  furigana: string;
  is_empty: number;
  status: number;
  section_name: string;
  medical_institution_id: number;
  medical_institution_name: string;
  doctor_contacts: Contact[];
}

export interface Contact {
  contact_type: number;
  contact_info: string;
}

/* iBowAI自動作成 契約状況 */
export interface IbowAiGenerateAgreementStatus {
  /** 契約状況(看護計画書) 0:未契約 1:契約済(利用不可) 2:契約済(利用可) */
  vn_plan_agreement_status: number;
  /** 契約状況(月次報告書) 0:未契約 1:契約済(利用不可) 2:契約済(利用可) */
  month_report_agreement_status: number;
  /** 開始日 */
  start_date: string;
  /** 終了日 */
  end_date: string;
  /** 契約開始日 */
  agreement_start_date: string;
  /** 契約終了日 */
  agreement_end_date: string;
}
