




























import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { Choice } from "@/types";

@Component
export default class AiGenerateButton extends Mixins(UtilMixin) {
  /** Prop */

  /** ボタン高さ */
  @Prop({ default: "36px" }) height!: string;

  /** AI自動作成中フラグ */
  @Prop({ default: false }) isAiGenerating!: boolean;

  /** AI自動作成契約フラグ */
  @Prop({ default: false }) isAiEnabled!: boolean;

  /** getter */

  /** AI自動作成ボタン選択肢 */
  private get ButtonOptions(): Choice[] {
    return this.isAiEnabled
      ? [
          {
            text: this.isAiGenerating ? "作成中" : "作成する",
            value: 1,
            disabled: this.isAiGenerating
          },
          {
            text: "履歴をみる",
            value: 2,
            disabled: false
          }
        ]
      : [];
  }

  /** 関数 */

  /** ボタン選択時 */
  private onSelect(value: number) {
    switch (value) {
      case 1:
        this.generate();
        break;
      case 2:
        this.history();
        break;
    }
  }

  /** Emit */

  /** 自動作成であることを通知 */
  @Emit("aigenerate:generate")
  private generate() {
    return;
  }

  /** 履歴であることを通知 */
  @Emit("aigenerate:history")
  private history() {
    return;
  }

  /** 契約申し込みが必要であることを通知 */
  @Emit("aigenerate:agreement")
  private agreement() {
    return;
  }
}
